<template>
  <div class="vacuum40">
    <div class="introduce">
      <div class="detailed detailed1">
        <div class="right container">
          <div class="content white">
            <h3></h3>
            <h4>Vacuum 40 {{this.$t('vacuum40.vacuum40')}}</h4>
            <p>
              {{this.$t('vacuum40.ItIsCompatible')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="parameter parameter2 container">
      <img src="../../assets/vacuum40/IMG_0050-577x500.43862056.png" alt="" />
      <div class="message">
        <h4 class="title">{{this.$t('vacuum40.ThoroughCleaning')}}</h4>
        <p v-html="getHtml('EachFunction')">
        </p>
      </div>
    </div>
    <div class="introduce">
      <div class="detailed detailed2">
        <div class="right container">
          <div class="content2 white">
            <h3></h3>
            <h4>{{this.$t('vacuum40.SimplifyingWorkflow')}}</h4>
            <p class="simplification">
              {{this.$t('vacuum40.Vacuum40Can')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="parameter container">
      <img
        class="photo"
        src="../../assets//vacuum40/Specifications-Img-Vacuum-40-min-1.0591186c.png"
        alt=""
      />
      <div class="message test">
        <h4 class="title">{{this.$t('vacuum40.Vacuum40Specifications')}}</h4>
        <p>
          <span class="textRight">{{this.$t('vacuum40.ProductSize')}}</span
          ><span>700（L）* 570（W）* 875（H）mm</span>
        </p>
        <p><span class="textRight">{{this.$t('vacuum40.BatteryCapacity')}}</span><span>7Li-ion 40 Ah</span></p>
        <p>
          <span class="textRight">{{this.$t('vacuum40.ContinuousUptime')}}</span
          ><span>2h</span>
        </p>
        <p>
          <span class="textRight">{{this.$t('vacuum40.KiyoaraiWidth')}}</span
          ><span>90*400 mm</span>
        </p>
        <p>
          <span class="textRight">{{this.$t('vacuum40.SensorConfiguration')}}</span><span>{{ this.$t('vacuum40.DeviceDisposition') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(text){
      return this.$t('vacuum40.'+text)
    }
  },
};
</script>

<style scoped lang='less'>
.vacuum40 {
  .introduce {
    .container {
      display: flex;
      align-items: center;
      height: 700px;
      .white {
        color: #fff;
        .simplification{
          line-height: 1.8;
        }
      }
      h3 {
        width: 512px;
        font-size: 30px;
      }
      h4 {
        width: 700px;
        font-size: 44px;
        font-weight: 700;
      }
      p {
        margin-top: 40px;
        display: inline-block;
        width: 700px;
        font-size: 16px;
      }
    }
    .left {
      display: flex;
      justify-content: flex-start;
    }
    .right {
      color: #000000;
      display: flex;
      justify-content: flex-end;
      .content2 {
        width: 580px;
        h4 {
          width: 580px;
          font-size: 40px;
          color: #0064e6;
        }
        p {
          width: 580px;
        }
      }
    }
    .detailed {
      background: no-repeat center;
      background-size: cover;
    }
    .detailed1 {
      background-image: url("~@/assets/vacuum40/Smart-Obstacle-Avoidance-D.-min.7b920454.png");
    }
    .detailed2 {
      background-image: url("~@/assets/vacuum40/Banner-Phantas-D.-1.png");
    }
  }
  .parameter {
    box-sizing: border-box;
    height: 647px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .photo {
      width: 577px;
      height: 560px;
    }
    .message {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 347px;
      h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 100px;
      }
      p {
        padding: 10px;
        font-size: 16px;
        color: #000;
        line-height: 1.2;
        display: flex;
        span {
          width: 380px;
          color: #0064e6;
          line-height: 24px;
        }
        .textRight {
          flex-shrink: 0;
          width: 210px;
          text-align: right;
          color: #000;
        }
        span:last-child{
          margin-left: 40px;
        }
      }
    }
  }
  .parameter2 {
    .message {
      width: 520px;
      height: 200px;
      .title {
        margin-bottom: 20px;
        text-align: left;
        font-size: 40px;
        line-height: 52px;
      }
      p {
        width: 560px;
        line-height: 40px;
      }
    }
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .introduce {
      h3 {
        width: 100% !important;
        font-size: 20px !important;
      }
      h4 {
        width: 100% !important;
        font-size: 26px !important;
      }
      p {
        width: 100% !important;
      }
      .content {
        padding: 0 10px;
        width: 100%;
      }
      .container {
        height: 400px !important;
      }
    }

    .parameter {
      padding: 30px 20px;
      width: unset !important;
      height: unset !important;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      .photo {
        width: 100%;
        height: unset;
      }
      .message {
        height: unset;

        h4 {
          font-size: 16px !important;
        }
        p {
          width: 100%;
          font-size: 18px !important;
          span{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
