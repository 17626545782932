<template>
  <div>
    <pc class="pc" v-if="!isMobile" />
    <mobile class="mobile" v-if="isMobile" />
  </div>
</template>
    
<script>
import pc from "./pc.vue";
import mobile from "./mobile.vue";
export default {
  components: {
    pc,
    mobile,
  },
  data() {
    return {
      // 定义一个变量表示是否是手机打开
      isMobile: false,
    };
  },
  created() {},
  mounted() {
    // 获取浏览器标识字符串
    var ua = navigator.userAgent;
    // 定义移动设备关键字数组
    var mobileKeys = ["Android", "iPhone", "iPad", "iPod", "Windows Phone"];
    // 遍历移动设备关键字数组，判断浏览器标识字符串是否包含其中之一
    for (var i = 0; i < mobileKeys.length; i++) {
      if (ua.indexOf(mobileKeys[i]) > -1) {
        // 如果包含，则说明是手机打开，将变量设置为true，并跳出循环
        this.isMobile = true;
        break;
      }
    }
  },
  methods: {},
};
</script>
    
<style scoped lang='less'>
</style>
    