<template>
  <div>
    <div class="header">
      <div class="nested-text">
        <p>Vacuum 40</p>
        <p>{{ this.$t("vacuum40.vacuum40") }}</p>
      </div>
    </div>
    <div class="content">
      <ul class="details">
        <li>
          <div>
            <p class="title">{{ this.$t("vacuum40.ThoroughCleaning") }}</p>
            <ul class="introduce">
              <li v-html="getHtml('EachFunction')"></li>
            </ul>
          </div>
          <img
            width="100%"
            src="../../assets/vacuum40/IMG_0050-577x500.43862056.png"
            alt=""
          />
        </li>
        <li>
          <div>
            <p class="title">{{ this.$t("vacuum40.SimplifyingWorkflow") }}</p>
            <p class="item-content">
              {{this.$t('vacuum40.Vacuum40Can')}}
            </p>
          </div>
          <img
            width="100%"
            src="../../assets/mobileVacuum40/IMG_0050-577x500.43862056(1).png"
            alt=""
          />
        </li>
      </ul>
    </div>
    <div class="footer">
      <img
        width="100%"
        src="../../assets/mobileVacuum40/Specifications-Img-Vacuum-40-min-1.0591186c.png"
        alt=""
      />
      <p class="footer-title">
        {{ this.$t("vacuum40.Vacuum40Specifications") }}
      </p>
      <ul class="config">
        <li>
          <p>{{ this.$t("vacuum40.ProductSize") }}</p>
          <p>700（L）* 570（W）* 875（H）mm</p>
        </li>
        <li>
          <p>{{ this.$t("vacuum40.BatteryCapacity") }}</p>
          <p>7Li-ion 40 Ah</p>
        </li>
        <li>
          <p>{{ this.$t("vacuum40.ContinuousUptime") }}</p>
          <p>2h</p>
        </li>
        <li>
          <p>{{ this.$t("vacuum40.KiyoaraiWidth") }}</p>
          <p>90*400 mm</p>
        </li>
        <li>
          <p>{{ this.$t("vacuum40.SensorConfiguration") }}</p>
          <p>
            {{ this.$t("vacuum40.DeviceDisposition") }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(text) {
      return this.$t("vacuum40." + text);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  background: url("../../assets/vacuum40/Smart-Obstacle-Avoidance-D.-min.7b920454.png")
    no-repeat center;
  background-size: cover;
  height: 56vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .nested-text {
    height: 15.4667vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 18.6667vw;
    p {
      font-family: 'Meiryooo';
    }
    p:first-child {
      font-size: 5.3333vw;
      font-weight: bold;
      line-height: 7.2vw;
      color: #ffffff;
    }
    p:last-child {
      font-size: 3.7333vw;
      font-weight: 500;
      line-height: 5.0667vw;
      color: #ffffff;
    }
  }
}
.content {
  .details {
    img {
      vertical-align: bottom;
    }
    li {
      div {
        margin: 10px 16px;
        margin-top: 20px;
        .title {
          font-size: 20px;
          font-family: 'Meiryooo';
          font-weight: bold;
          line-height: 27px;
          color: #333333;
          margin-bottom: 12px;
        }
        ul {
          font-size: 12px;
          font-family:'Meiryooo';
          font-weight: 400;
          line-height: 24px;
          // margin-bottom: 20px;
          li {
          }
        }
        .item-content {
          font-size: 12px;
          font-family:'Meiryooo';
          font-weight: 400;
          line-height: 24px;
          color: #333333;
        }
      }
    }
  }
  img {
    vertical-align: bottom;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 4vw;

  .footer-title {
    font-size: 3.7333vw;
    font-family:'Meiryooo';
    font-weight: bold;
    line-height: 5.0667vw;
    color: #000000;
    margin-top: 14.6667vw;
  }
  .config {
    margin-top: 4vw;
    li {
      display: flex;
      margin: 0 9.0667vw;
      margin-bottom: 2.6667vw;
      p {
        font-size: 3.2vw;
        font-family: 'Meiryooo';
        //   font-weight: ;
        line-height: 4.2667vw;
        color: #000000;
      }

      p:first-child {
        flex-shrink: 0;
        color: #000000;
        width: 23vw;
        text-align: right;
        flex-wrap: nowrap;
      }
      p:last-child {
        flex-grow: 1;
        margin-left: 2.6667vw;
        color: #0064e6;
      }
    }
    li:nth-child(2) {
      p:first-child {
        width: 28vw;
        margin-left: -5vw;
      }
    }
    li:last-child {
      margin-bottom: 10.6667vw;
    }
  }
}
</style>